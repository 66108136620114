import { v4 as uuidv4 } from 'uuid';

import image01Thumb from './assets/ml-site/gallery-thumbs/_DSC9983.jpg';
import image02Thumb from './assets/ml-site/gallery-thumbs/_MG_0695.jpg';
import image03Thumb from './assets/ml-site/gallery-thumbs/_MG_0906.jpg';
import image04Thumb from './assets/ml-site/gallery-thumbs/_MG_2469.jpg';
import image05Thumb from './assets/ml-site/gallery-thumbs/_MG_4758.jpg';
import image06Thumb from './assets/ml-site/gallery-thumbs/_MG_8126.jpg';
import image07Thumb from './assets/ml-site/gallery-thumbs/1559365_10152441381732528_351142956684085008_o.jpg';
import image08Thumb from './assets/ml-site/gallery-thumbs/10014951_10152470748467393_8327387444239303250_o.jpg';
import image09Thumb from './assets/ml-site/gallery-thumbs/10262084_10152403751212393_2808119162827304355_n.jpg';
import image10Thumb from './assets/ml-site/gallery-thumbs/10333700_10152092005892393_8668079849891397942_o.jpg';
import image11Thumb from './assets/ml-site/gallery-thumbs/10344181_10152441381667528_4464609870163684090_o.jpg';
import image12Thumb from './assets/ml-site/gallery-thumbs/10380653_10153035638764500_1152729674545508054_o.jpg';
import image13Thumb from './assets/ml-site/gallery-thumbs/10629279_10152475239827393_8348737741787427337_o.jpg';
import image14Thumb from './assets/ml-site/gallery-thumbs/10945465_10153035638769500_2318659696772995031_o.jpg';
import image15Thumb from './assets/ml-site/gallery-thumbs/12593807_10153342786572393_4308920063937101085_o.jpg';
import image16Thumb from './assets/ml-site/gallery-thumbs/Headshot.jpg';
import image17Thumb from './assets/ml-site/gallery-thumbs/IMG_0787.jpg';
import image18Thumb from './assets/ml-site/gallery-thumbs/sm-matt-lemche-headshot-button-up.jpg';

import image01 from './assets/ml-site/gallery-full/_DSC9983.jpg';
import image02 from './assets/ml-site/gallery-full/_MG_0695.jpg';
import image03 from './assets/ml-site/gallery-full/_MG_0906.jpg';
import image04 from './assets/ml-site/gallery-full/_MG_2469.jpg';
import image05 from './assets/ml-site/gallery-full/_MG_4758.jpg';
import image06 from './assets/ml-site/gallery-full/_MG_8126.jpg';
import image07 from './assets/ml-site/gallery-full/1559365_10152441381732528_351142956684085008_o.jpg';
import image08 from './assets/ml-site/gallery-full/10014951_10152470748467393_8327387444239303250_o.jpg';
import image09 from './assets/ml-site/gallery-full/10262084_10152403751212393_2808119162827304355_n.jpg';
import image10 from './assets/ml-site/gallery-full/10333700_10152092005892393_8668079849891397942_o.jpg';
import image11 from './assets/ml-site/gallery-full/10344181_10152441381667528_4464609870163684090_o.jpg';
import image12 from './assets/ml-site/gallery-full/10380653_10153035638764500_1152729674545508054_o.jpg';
import image13 from './assets/ml-site/gallery-full/10629279_10152475239827393_8348737741787427337_o.jpg';
import image14 from './assets/ml-site/gallery-full/10945465_10153035638769500_2318659696772995031_o.jpg';
import image15 from './assets/ml-site/gallery-full/12593807_10153342786572393_4308920063937101085_o.jpg';
import image16 from './assets/ml-site/gallery-full/Headshot.jpg';
import image17 from './assets/ml-site/gallery-full/IMG_0787.jpg';
import image18 from './assets/ml-site/gallery-full/sm-matt-lemche-headshot-button-up.jpg';

export const galleryMisc = [
    { 
        id: uuidv4(),
        url: image18Thumb,
        urlFull: image18,
        alt: 'Photo of Matt Lemche'
    },
    { 
        id: uuidv4(),
        url: image17Thumb,
        urlFull: image17,
        alt: 'Photo of Matt Lemche'
    },
    { 
        id: uuidv4(),
        url: image16Thumb,
        urlFull: image16,
        alt: 'Photo of Matt Lemche'
    },
];

export const galleryEe = [
    { 
        id: uuidv4(),
        url: image15Thumb,
        urlFull: image15,
        alt: 'Photo of Matt Lemche performing on stage with Elephant Empire'
    },
    { 
        id: uuidv4(),
        url: image14Thumb,
        urlFull: image14,
        alt: 'Photo of Matt Lemche performing on stage with Elephant Empire'
    },
    { 
        id: uuidv4(),
        url: image13Thumb,
        urlFull: image13,
        alt: 'Photo of Matt Lemche performing on stage with Elephant Empire'
    },
    { 
        id: uuidv4(),
        url: image12Thumb,
        urlFull: image12,
        alt: 'Photo of Matt Lemche performing on stage with Elephant Empire'
    },
    { 
        id: uuidv4(),
        url: image11Thumb,
        urlFull: image11,
        alt: 'Photo of Matt Lemche performing on stage with Elephant Empire'
    },
    { 
        id: uuidv4(),
        url: image10Thumb,
        urlFull: image10,
        alt: 'Promotional image for Elephant Empire'
    },
    { 
        id: uuidv4(),
        url: image09Thumb,
        urlFull: image09,
        alt: 'Still from Elephant Empire Comedy Coup with CBC'
    },
    { 
        id: uuidv4(),
        url: image08Thumb,
        urlFull: image08,
        alt: 'Photo of Matt Lemche performing on stage with Elephant Empire'
    },
    { 
        id: uuidv4(),
        url: image07Thumb,
        urlFull: image07,
        alt: 'Photo of Matt Lemche performing on stage with Elephant Empire'
    }
];

export const galleryMoby = [
    { 
        id: uuidv4(),
        url: image06Thumb,
        urlFull: image06,
        alt: 'Photo of Matt Lemche on set of TV Mini Series Moby Dick'
    },
    { 
        id: uuidv4(),
        url: image05Thumb,
        urlFull: image05,
        alt: 'Photo of Matt Lemche on set of TV Mini Series Moby Dick'
    },
    { 
        id: uuidv4(),
        url: image04Thumb,
        urlFull: image04,
        alt: 'Photo of Matt Lemche on set of TV Mini Series Moby Dick'
    },
    { 
        id: uuidv4(),
        url: image03Thumb,
        urlFull: image03,
        alt: 'Photo of Matt Lemche on set of TV Mini Series Moby Dick'
    },
    { 
        id: uuidv4(),
        url: image02Thumb,
        urlFull: image02,
        alt: 'Photo of Matt Lemche on set of TV Mini Series Moby Dick'
    },

    { 
        id: uuidv4(),
        url: image01Thumb,
        urlFull: image01,
        alt: 'Photo of Matt Lemche on set of TV Mini Series Moby Dick'
    },
];

const galleryArray = [
    { 
        id: uuidv4(),
        url: image01Thumb,
        urlFull: image01,
        alt: ''
    },
    { 
        id: uuidv4(),
        url: image02Thumb,
        urlFull: image02,
        alt: ''
    },
    { 
        id: uuidv4(),
        url: image03Thumb,
        urlFull: image03,
        alt: ''
    },
    { 
        id: uuidv4(),
        url: image04Thumb,
        urlFull: image04,
        alt: ''
    },
    { 
        id: uuidv4(),
        url: image05Thumb,
        urlFull: image05,
        alt: ''
    },
    { 
        id: uuidv4(),
        url: image06Thumb,
        urlFull: image06,
        alt: ''
    },
    { 
        id: uuidv4(),
        url: image07Thumb,
        urlFull: image07,
        alt: ''
    },
    { 
        id: uuidv4(),
        url: image08Thumb,
        urlFull: image08,
        alt: ''
    },
    { 
        id: uuidv4(),
        url: image09Thumb,
        urlFull: image09,
        alt: ''
    },
    { 
        id: uuidv4(),
        url: image10Thumb,
        urlFull: image10,
        alt: ''
    },
    { 
        id: uuidv4(),
        url: image11Thumb,
        urlFull: image11,
        alt: ''
    },
    { 
        id: uuidv4(),
        url: image12Thumb,
        urlFull: image12,
        alt: ''
    },
    { 
        id: uuidv4(),
        url: image13Thumb,
        urlFull: image13,
        alt: ''
    },
    { 
        id: uuidv4(),
        url: image14Thumb,
        urlFull: image14,
        alt: ''
    },
    { 
        id: uuidv4(),
        url: image15Thumb,
        urlFull: image15,
        alt: ''
    },
    { 
        id: uuidv4(),
        url: image16Thumb,
        urlFull: image16,
        alt: ''
    },
    { 
        id: uuidv4(),
        url: image17Thumb,
        urlFull: image17,
        alt: ''
    }
];

export const galleryView = galleryArray.reverse();

export const filmTvCredits = [
    { 
        id: uuidv4(),
        title: 'Murdoch Mysteries',
        format: 'Series',
        role: 'William Price',
        director: 'Jayson Clute',
        year: '2024'
    },{ 
        id: uuidv4(),
        title: 'Fellow Travelers',
        format: 'Series',
        role: 'Roger',
        director: 'Uta Briesewitz',
        year: '2023'
    },{ 
        id: uuidv4(),
        title: 'Washington Black',
        format: 'Series',
        role: 'Mouse',
        director: 'Multiple',
        year: '2025'
    },{ 
        id: uuidv4(),
        title: 'The Mortal Decree',
        format: 'Short',
        role: 'Chaplin',
        director: 'Henry Fabergé',
        year: '2021'
    },
    { 
        id: uuidv4(),
        title: 'Saving Hope',
        format: 'Series',
        role: 'Bart Dolson',
        director: 'Steve DiMarco',
        year: '2017'
    },
    { 
        id: uuidv4(),
        title: 'The Strain',
        format: 'Series',
        role: 'Hazmat Worker',
        director: 'Guillermo del Toro',
        year: '2014'
    },
    { 
        id: uuidv4(),
        title: 'Lost Girl',
        format: 'Series',
        role: 'Tad',
        director: 'Director X.',
        year: '2014'
    },
    { 
        id: uuidv4(),
        title: 'Defiance',
        format: 'Series',
        role: `'Black' Jonah Keller`,
        director: 'Michael Nankin',
        year: '2013'
    },
    { 
        id: uuidv4(),
        title: 'This Feels Nice',
        format: 'Short',
        role: 'Andrew',
        director: 'Michelle Ouellet',
        year: '2014'
    },{ 
        id: uuidv4(),
        title: 'Rookie Blue',
        format: 'Series',
        role: 'Photographer',
        director: 'David Wellington',
        year: '2012'
    },
    { 
        id: uuidv4(),
        title: 'The Secret of Goat',
        format: 'Short',
        role: 'Jurgen',
        director: 'Park Bench',
        year: '2012'
    },
    { 
        id: uuidv4(),
        title: 'Suits',
        format: 'Series',
        role: 'Frank Carvello',
        director: 'Dennie Gordon',
        year: '2011'
    },
    { 
        id: uuidv4(),
        title: 'Warehouse 13',
        format: 'Series',
        role: 'Bellman',
        director: 'Stephen Surjik',
        year: '2011'
    },
    { 
        id: uuidv4(),
        title: 'Moby Dick',
        format: 'Mini Series',
        role: 'Flask',
        director: 'Mike Barker',
        year: '2011'
    },
    { 
        id: uuidv4(),
        title: 'Flashpoint',
        format: 'Series',
        role: 'Brett Fawcett',
        director: 'Charles Binamé',
        year: '2011'
    },
    { 
        id: uuidv4(),
        title: 'The Boondock Saints II:',
        titleTwo: 'All Saints Day',
        format: 'Feature',
        role: 'Noah',
        director: 'Troy Duffy',
        year: '2009'
    },
    { 
        id: uuidv4(),
        title: 'Instant Star',
        format: 'Series',
        role: 'Mike',
        director: 'Graeme Campbell',
        year: '2008'
    },
    { 
        id: uuidv4(),
        title: 'M.V.P.',
        format: 'Series',
        role: 'James',
        director: 'T.W. Peacocke',
        year: '2008'
    },
    { 
        id: uuidv4(),
        title: 'Matters of Life & Dating',
        format: 'TV Movie',
        role: 'Pete',
        director: 'Peter Wellington',
        year: '2007'
    },
    { 
        id: uuidv4(),
        title: 'The Best Years',
        format: 'Series',
        role: 'Steve',
        director: 'Chris Grismer',
        year: '2007'
    },
    { 
        id: uuidv4(),
        title: 'The Love Box',
        format: 'Short',
        role: 'Samuel',
        director: 'Christopher Cordell',
        year: '2007'
    },
    { 
        id: uuidv4(),
        title: 'Most Likely To',
        format: 'Short',
        role: 'Adam',
        director: 'Matt Sadowski',
        year: '2007'
    },
    { 
        id: uuidv4(),
        title: 'Rabittown',
        format: 'TV Movie',
        role: 'Brian',
        director: 'James Genn',
        year: '2006'
    },
    { 
        id: uuidv4(),
        title: 'This is Wonderland',
        format: 'Series',
        role: 'Eric Calvo',
        director: 'Chris Grismer',
        year: '2005'
    },
    { 
        id: uuidv4(),
        title: 'Dark Water',
        format: 'Feature',
        role: 'Steve',
        director: 'Walter Salles',
        year: '2005'
    },
    { 
        id: uuidv4(),
        title: 'Kojak',
        format: 'Series',
        role: 'Larry Davis',
        director: 'Norberto Barba',
        year: '2005'
    },
    { 
        id: uuidv4(),
        title: 'Tilt',
        format: 'Series',
        role: 'Charles Heidman',
        director: 'John Dahl',
        year: '2005'
    },
    { 
        id: uuidv4(),
        title: 'True Crimes:',
        titleTwo: 'The First 72 Hours',
        format: 'Series',
        role: 'Jason',
        director: 'Jonny Silver',
        year: '2004'
    },
    { 
        id: uuidv4(),
        title: '1-800-Missing',
        format: 'Series',
        role: 'Private Giovener',
        director: 'David Wu',
        year: '2003'
    },
    { 
        id: uuidv4(),
        title: 'The Quarry',
        format: 'Short',
        role: 'Todd',
        director: 'Svjetlana Jaklenec',
        year: '2002'
    },
    { 
        id: uuidv4(),
        title: 'I\'m Not Martin',
        format: 'Short',
        role: 'Martin',
        director: 'Pat Mills',
        year: '2000'
    },
    { 
        id: uuidv4(),
        title: 'The City',
        format: 'Series',
        role: 'Strachan Berg',
        director: 'Multiple',
        year: '1999–2000'
    },
    { 
        id: uuidv4(),
        title: 'Twice in a Lifetime',
        format: 'Series',
        role: 'Multiple',
        director: 'Multiple',
        year: '2000'
    },
    { 
        id: uuidv4(),
        title: 'Bless the Child',
        format: 'Feature',
        role: 'New Dawn Kid',
        director: 'Chuck Russell',
        year: '2000'
    },
    { 
        id: uuidv4(),
        title: 'Real Kids, Real Adventure',
        format: 'Series',
        role: 'Kyle Hale',
        director: '',
        year: '2000'
    },
    { 
        id: uuidv4(),
        title: 'Are You Afraid of the Dark?',
        format: 'Series',
        role: 'Craig',
        director: 'Jim Donovan',
        year: '2000'
    },
    { 
        id: uuidv4(),
        title: 'Flash Forward',
        format: 'Series',
        role: 'Roland Nembhard',
        director: 'Multiple',
        year: '1997'
    },
    { 
        id: uuidv4(),
        title: 'Honey I Shrunk the Kids:',
        titleTwo: 'The TV Show',
        format: 'Series',
        role: 'Young Wayne Szalinski',
        director: 'Tony Dow',
        year: '1997'
    },
    { 
        id: uuidv4(),
        title: 'The New Ghostwriter Mysteries',
        format: 'Series',
        role: 'Brandon',
        director: 'Gail Harvey',
        year: '1997'
    },
    { 
        id: uuidv4(),
        title: 'PSI Factor:',
        titleTwo: 'Chronicles of the Paranormal',
        format: 'Series',
        role: 'Tyler Jessop',
        director: 'Milan Cheylov',
        year: '1997'
    },
    { 
        id: uuidv4(),
        title: 'Goosebumps',
        format: 'Series',
        role: 'Multiple',
        director: 'Multiple',
        year: '1997'
    }
];

export const theatreCredits = [
    { 
        id: uuidv4(),
        title: 'Elephant Empire',
        format: 'Sketch Comedy',
        role: 'Various',
        director: 'Peter Stevens',
        year: '2007–Present'
    },
    { 
        id: uuidv4(),
        title: 'Goodnight, Amherst',
        format: 'Play',
        role: 'Sean Anderson',
        director: 'Carrie Adelstein',
        year: ''
    },
    { 
        id: uuidv4(),
        title: 'Easy',
        format: 'Play',
        role: 'Harmony',
        director: 'Matt Hilliard-Forde',
        year: ''
    },
    { 
        id: uuidv4(),
        title: 'Thousand Dollar Zombie',
        format: 'Play',
        role: 'Donnie Gaston',
        director: 'Bendigo Film Company',
        year: ''
    },
    { 
        id: uuidv4(),
        title: 'Laughter on the 23rd Floor',
        format: 'Play',
        role: 'Lucas',
        director: 'Brian Bird',
        year: ''
    },
    { 
        id: uuidv4(),
        title: 'Pippin',
        format: 'Musical',
        role: 'Pippin',
        director: 'Ken Stern',
        year: ''
    },
    { 
        id: uuidv4(),
        title: 'Noises Off',
        format: 'Play',
        role: 'Tim Allgood',
        director: 'Brian Bird',
        year: ''
    },
    { 
        id: uuidv4(),
        title: 'The Melville Boys',
        format: 'Play',
        role: 'Owen Melville',
        director: 'Brian Bird',
        year: ''
    },
    { 
        id: uuidv4(),
        title: 'Cabaret',
        format: 'Musical',
        role: 'Emcee',
        director: 'Ken Stern',
        year: ''
    },
    { 
        id: uuidv4(),
        title: 'The Odd Couple',
        format: 'Play',
        role: 'Vinnie',
        director: 'Brian Bird',
        year: ''
    },
    { 
        id: uuidv4(),
        title: 'Run for Your Wife',
        format: 'Play',
        role: 'Bobby Franklin',
        director: 'Brian Bird',
        year: ''
    }
];

export const trainingCredits = [
    { title: ''}
];
