import React from 'react';

const DemoReel = () => {
    return (
        <div className="demo-reel">
            <div className="iframe-container">
            <iframe src="https://player.vimeo.com/video/552025559?h=aa017515ac" title="ML Demo Reel" width="640" height="360" frameBorder="0" allow="autoplay; fullscreen; picture-in-picture"></iframe>

            </div>
            <a href="https://design.mattlemche.com/web-and-mobile" className="link demo-reel__link" target="_blank" rel="noopener noreferrer">
                Demo reel produced by ML Designs.
            </a>
            
        </div>
    );
};

export default DemoReel;