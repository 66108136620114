import React from 'react';
import {ReactComponent as Instagram} from '../../assets/ml-site/icon-instagram.svg';

const Footer = () => {
    return (
        <footer className="footer">
            <div className="footer__content">
                <div className="contact">
                    <h5 className="footer__label">
                        Contact
                    </h5>
                    <ul className="contact__list">
                        <li className="contact__link">
                            <a href="https://www.thecharacters.com/" className="linnk link--contact" target="_blank" rel="noopener noreferrer"> 
                            The Characters Talent Agency
                            </a>
                            <p className="footer__label">Michael White | <span className="text-block link--contact">
                            Agent, Toronto
                            </span></p>
                            
                        </li>
                    </ul>
                    <h5 className="footer__label">
                        Social
                    </h5>
                    <ul className="contact__list">
                        <li className="contact__link">
                            <a href="https://www.instagram.com/mattlemche/" className="link link--contact" target="_blank" rel="noopener noreferrer">
                                <Instagram className="contact__icon contact__icon--github"/>instagram.com/mattlemche/
                            </a>
                        </li>
                    </ul>
                    
                </div>
                <div className="causes">
                    <h5 className="footer__label">
                        Vital Causes
                    </h5>
                    <ul className="causes__list">
                        <li className="causes__link">
                            <a href="https://blacklivesmatter.ca/" className="link link--causes" target="_blank" rel="noopener noreferrer">
                                Black Lives Matter
                            </a>
                        </li>   
                        <li className="causes__link">
                            <a href="https://soytoronto.com/" className="link link--causes" target="_blank" rel="noopener noreferrer">
                                Support 2SLGBTQIA+ Youth
                            </a>
                        </li> 
                        <li className="causes__link">
                            <a href="https://idlenomore.ca/" className="link link--causes" target="_blank" rel="noopener noreferrer">
                                Honour Indigenous Sovereignty
                            </a>
                        </li>
                    </ul>
                </div>
                <a href="https://design.mattlemche.com/web-and-mobile" className="link link--footer" target="_blank" rel="noopener noreferrer">
                    Website design and development by ML Designs
                </a>
            </div>
        </footer>
    );
};

export default Footer;